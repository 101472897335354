@font-face {
    font-family: "vw-head";
    src: url("./VWHeadWeb-Light-subset.eot");
    src: url("./VWHeadWeb-Light-subset.eot?#iefix")
            format("embedded-opentype"),
        url("./VWHeadWeb-Light-subset.woff2") format("woff2"),
        url("./VWHeadWeb-Light-subset.woff") format("woff"),
        url("./VWHeadWeb-Light-subset.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "vw-head";
    src: url("./VWHeadWeb-Bold-subset.eot");
    src: url("./VWHeadWeb-Bold-subset.eot?#iefix")
            format("embedded-opentype"),
        url("./VWHeadWeb-Bold-subset.woff2") format("woff2"),
        url("./VWHeadWeb-Bold-subset.woff") format("woff"),
        url("./VWHeadWeb-Bold-subset.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "vw-text";
    src: url("./VWTextWeb-Regular-subset.eot");
    src: url("./VWTextWeb-Regular-subset.eot?#iefix")
            format("embedded-opentype"),
        url("./VWTextWeb-Regular-subset.woff2") format("woff2"),
        url("./VWTextWeb-Regular-subset.woff") format("woff"),
        url("./VWTextWeb-Regular-subset.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "vw-text";
    src: url("./VWTextWeb-Bold-subset.eot");
    src: url("./VWTextWeb-Bold-subset.eot?#iefix")
            format("embedded-opentype"),
        url("./VWTextWeb-Bold-subset.woff2") format("woff2"),
        url("./VWTextWeb-Bold-subset.woff") format("woff"),
        url("./VWTextWeb-Bold-subset.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* $vw-head: 'vw-head', "VW Head", Helvetica, Arial, sans-serif;
$vw-text: 'vw-text', "VW Text", Helvetica, Arial, sans-serif; */